<template>
	<div class="pdpage">
		<div style="height: 40px;">

			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>首页</el-breadcrumb-item>
				<el-breadcrumb-item>打印机列表</el-breadcrumb-item>

			</el-breadcrumb>
		</div>
		<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">

			<el-table-column type="index" width="80" label="序号">
			</el-table-column>
			<el-table-column prop="device_id" label="打印机ID" min-width="150">

			</el-table-column>
			<el-table-column prop="device_name" label="打印机名称" min-width="100"></el-table-column>
			<el-table-column prop="now_paper" label="纸张类型" width="120">
				<template slot-scope="scope">
				
					<span @click="showPrint2(scope.row)"  style="color: deepskyblue; font-weight: bolder;">
						{{scope.row.now_paper}}
					</span>
					
				</template>
			</el-table-column>
			<el-table-column prop="status_name" label="打印状态" width="100"></el-table-column>
			<el-table-column prop="onstate" label="启用状态" width="100">
				<template slot-scope="scope">

					<span @click="showPrint2(scope.row)" v-if="scope.row.onstate==1" style="color: deepskyblue;">
						<el-tooltip class="item" effect="dark" content="当前打印机启用状态,可以参与接单" placement="top">
							<span>已启用 <i class="el-icon-question"></i></span>
						</el-tooltip>

					</span>
					<span  @click="showPrint2(scope.row)"  v-else style="color: orangered;">
						<el-tooltip class="item" effect="dark" content="当前打印机禁用状态,无法打印,请启用后参与接单!" placement="top">
							<span>已禁用 <i class="el-icon-question"></i></span>
						</el-tooltip>
					</span>
				</template>
			</el-table-column>


			<el-table-column fixed="right" label="操作" width="100">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="showPrint(scope.row)">配置</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog :close-on-click-modal="false" title="打印机配置" :visible.sync="visibleModal" width="800px">
			<el-form ref="ruleForm" label-width="150px" v-model="printer" v-if="printer">
				<el-form-item label="打印机ID：" prop="title">
					<el-input disabled v-model="printer.device_id" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="打印机名称：" prop="device_name">
					<el-input v-model="printer.device_name" style="width: 80%;"></el-input><span
						style="color: orangered;">*</span>
					<div style="color: blue; font-size: 12px; line-height: 20px;">打印机名称是别人在使用共享打印机时可以看到的名称,例如：二号打印机
					</div>
				</el-form-item>

				<!-- <el-form-item label="打印机型号：" prop="title">
						<el-input   v-model="printer.device_xh"></el-input>
						
				</el-form-item> -->
				<el-form-item label="当前纸类型：" prop="title">

					<el-radio-group v-model="printer.now_paper">
						<el-radio label="A4">A4</el-radio>
						<el-radio label="A3">A3</el-radio>
						<el-radio label="照片6寸">照片6寸</el-radio>
						<el-radio label="照片12寸">照片12寸</el-radio>

					</el-radio-group>
				</el-form-item>
				<el-form-item label="启用打印机：" prop="onstate">
					<el-radio-group v-model="printer.onstate">
						<el-radio :label="0">不启用</el-radio>
						<el-radio :label="1">启用</el-radio>
					</el-radio-group>
					<span style="color: orangered;">*</span>

					<span style="color: orangered; font-size: 12px; margin-left: 20px;">不启用该打印机则不会共享给其他人使用</span>


				</el-form-item>
				<el-form-item label="自动双面打印：" prop="title">
					<el-radio-group v-model="printer.sm_print_auto">
						<el-radio :label="0">不支持</el-radio>
						<el-radio :label="1">支持</el-radio>

					</el-radio-group>


				</el-form-item>

				<el-form-item label="是否可彩打：" prop="title">
					<el-radio-group v-model="printer.color">
						<el-radio :label="0">只能黑白打印</el-radio>
						<el-radio :label="1">支持彩色打印</el-radio>

					</el-radio-group>


				</el-form-item>

				<!-- <el-form-item label="可打印纸张类型：" prop="title">

					<el-checkbox-group v-model="printer.paper_type">
						<el-checkbox label="A4">A4</el-checkbox>
						<el-checkbox label="A3">A3</el-checkbox>
						<el-checkbox label="6寸">6寸</el-checkbox>
						<el-checkbox label="12寸">12寸</el-checkbox>

					</el-checkbox-group>
					<div>设置后方便快速切换纸型</div>

				</el-form-item> -->
				<!-- <el-form-item label="是否可打印照片：" prop="title">
					<el-radio-group v-model="printer.printer_pic">
						<el-radio :label="0">不支持</el-radio>
						<el-radio :label="1">支持</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="可打印照片纸尺寸：" prop="title" v-if="printer.printer_pic">

					<el-checkbox-group v-model="printer.pic_type">
						<el-checkbox label="6寸">6寸</el-checkbox>
						<el-checkbox label="12寸">12寸</el-checkbox>

					</el-checkbox-group>

				</el-form-item> -->



				<div style="text-align: right;">

					<el-button size="small" @click="savePrint()" type="primary" icon="el-icon-check">保存</el-button>
				</div>

			</el-form>
		</el-dialog>
		
		
		<el-dialog :close-on-click-modal="false" title="快速开启打印机" :visible.sync="easyModalShow" width="800px">
			<el-form ref="ruleForm" label-width="150px" v-model="printer" v-if="printer">
				<el-form-item label="打印机ID：" prop="title">
					<el-input disabled v-model="printer.device_id" style="width: 80%;"></el-input>
				</el-form-item>
				
				<el-form-item label="当前纸类型：" prop="title">
		
					<el-radio-group v-model="printer.now_paper">
						<el-radio label="A4">A4</el-radio>
						<el-radio label="A3">A3</el-radio>
						<el-radio label="照片6寸">照片6寸</el-radio>
						<el-radio label="照片12寸">照片12寸</el-radio>
		
					</el-radio-group>
				</el-form-item>
				<el-form-item label="启用打印机：" prop="onstate">
					<el-radio-group v-model="printer.onstate">
						<el-radio :label="0">不启用</el-radio>
						<el-radio :label="1">启用</el-radio>
					</el-radio-group>
					<span style="color: orangered;">*</span>
		
					<span style="color: orangered; font-size: 12px; margin-left: 20px;">不启用该打印机则不会共享给其他人使用</span>
		
		
				</el-form-item>
				
		
		
		
				<div style="text-align: right;">
		
					<el-button size="small" @click="savePrint2()" type="primary" icon="el-icon-check">保存</el-button>
				</div>
		
			</el-form>
		</el-dialog>
		
		

	</div>
</template>

<script>
	export default {
		data() {
			return {
				visibleModal: false,
				easyModalShow:false,
				DataList: [],
				printer: null
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post("/api/shop_printer_list", {}).then(res => {

					this.DataList = res.data
				})
			},
			showPrint(e) {
				let p = JSON.parse(JSON.stringify(e))
				p.paper_type = p.paper_type ? p.paper_type.split(",") : ['A4'];
				p.pic_type = p.pic_type ? p.pic_type.split(",") : [];
				p.onstate ? p.onstate = 1 : p.onstate = 0;
				p.now_paper ? true : p.now_paper = 'A4'
				p.color ? true : p.color = 0
				p.sm_print_auto ? true : p.sm_print_auto = 0
				p.printer_pic ? true : p.printer_pic = 0
				p.device_name ? true : p.device_name = p.device_id
				this.printer = p
				this.visibleModal = true
			},
			savePrint() {
				if (!this.printer.device_name) {
					this.$message.error("请输入打印机名称")
					return
				}
				let p = JSON.parse(JSON.stringify(this.printer))
				p.paper_type = p.paper_type.join(",")
				p.pic_type = p.pic_type.join(",")
				console.log(p)
				this.$http.post("/api/set_print_info", p).then(res => {
					this.visibleModal = false
					this.$message.success("保存成功！")
					this.getList()
				})

			},
			showPrint2(e){
				if(!e.device_name){
					this.$message.error("请先完善打印机配置")
					return
				}
				let p = JSON.parse(JSON.stringify(e))
				p.paper_type = p.paper_type ? p.paper_type.split(",") : ['A4'];
				p.pic_type = p.pic_type ? p.pic_type.split(",") : [];
				p.onstate ? p.onstate = 1 : p.onstate = 0;
				p.now_paper ? true : p.now_paper = 'A4'
				p.color ? true : p.color = 0
				p.sm_print_auto ? true : p.sm_print_auto = 0
				p.printer_pic ? true : p.printer_pic = 0
				p.device_name ? true : p.device_name = p.device_id
				this.printer = p
				this.easyModalShow = true
			},
			savePrint2() {
				
				
				this.$http.post("/api/change_printer_paper", this.printer).then(res => {
					this.easyModalShow = false
					this.$message.success("保存成功！")
					this.getList()
				})
			
			},
		}
	}
</script>

<style>
</style>
